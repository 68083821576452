import { firestore as db, FirebaseFirestore } from "../firebase";

export const firestore = db;
export const Timestamp = FirebaseFirestore.Timestamp;
export const GeoPoint = FirebaseFirestore.GeoPoint;
export const DELETE = FirebaseFirestore.FieldValue.delete();
export const FieldValue = FirebaseFirestore.FieldValue;
export const TIMESTAMP =
  FirebaseFirestore.FieldValue.serverTimestamp() as firebase.default.firestore.Timestamp;
export function increment(step: number): number {
  return FirebaseFirestore.FieldValue.increment(step) as unknown as number;
}
export function arrayUnion<T>(...elements: Array<T>): Array<T> {
  return FirebaseFirestore.FieldValue.arrayUnion(...elements) as unknown as Array<T>;
}
export function arrayRemove<T>(...elements: Array<T>): Array<T> {
  return FirebaseFirestore.FieldValue.arrayRemove(...elements) as unknown as Array<T>;
}
export const SHARD_COLLECTION_ID = "_counter_shards_";
export const merge = { merge: true };

export type {
  CollectionReference as CollectionRef,
  DocumentReference as DocumentRef,
  QueryDocumentSnapshot as QueryDocumentSnap,
  Query,
  QuerySnapshot as QuerySnap,
  FirestoreError
} from "firebase/firestore";

export {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
  writeBatch,
  endAt,
  startAt
} from "firebase/firestore";
